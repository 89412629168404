import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Client = () => {
  return (
    <ul className="flex pc:justify-center sp:justify-between pc:max-w-4xl pc:mx-auto sp:px-5">
      <li className="text-center">
        <p className="font-bold poppins">株式会社JUT JOY</p>
        <StaticImage
          src="../../../images/company/jj.png"
          className="pc:w-48 sp:w-32"
          quality={95}
          alt="株式会社JUT JOY"
        />
      </li>
      <li className="pc:ml-10 text-center">
        <p className="mb-8 font-bold poppins">株式会社ファイン</p>
        <StaticImage
          src="../../../images/company/fine.png"
          className="pc:w-48 sp:w-32"
          quality={95}
          alt="株式会社Fi-Ne"
        />
      </li>
    </ul>
  )
}

export default Client
