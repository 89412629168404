import * as React from "react"

const overviews = [
  {
    title: "社名",
    text: "株式会社FunMeets",
  },
  {
    title: "代表",
    text: "岩淵 健太",
  },
  {
    title: "住所",
    text: "〒150-0041",
    subText: "渋谷区神南1-20-2第一清水ビル4F",
  },
  {
    title: "設立",
    text: "2021年11月24日",
  },
  {
    title: "資本金",
    text: "500万円",
  },
  {
    title: "事業内容",
    text: "教育 / SES / 受託開発 / 採用支援",
  },
]

const OverView = () => {
  return (
    <ul className="pc:max-w-4xl pc:mx-auto sp:px-5">
      {overviews.map(item => (
        <li
          key={item.title}
          className="flex items-center first:mt-0 mt-8 pc:px-8 pb-2 border-b border-gray-light pc:text-lg"
        >
          <p className="pc:w-44 sp:w-32 font-bold">{item.title}</p>
          <div className="">
            <p className="">{item.text}</p>
            {item.subText && <p className="">{item.subText}</p>}
          </div>
        </li>
      ))}
    </ul>
  )
}

export default OverView
